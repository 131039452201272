/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listFirmManager = params => axios({
    url: '/api/base/system/firm/manager/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFirmManager = params => axios({
    url: '/api/base/system/firm/manager/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFirmManager = params => axios({
    url: '/api/base/system/firm/manager/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFirmManager = params => axios({
    url:'/api/base/system/firm/manager/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFirmManager = params => axios({
    url: '/api/base/system/firm/manager/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
