<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-04 10:57:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-21 21:34:40
-->
<template>
  <div class="user-modal">
    <a-modal title="新增" v-model="visible" width="70%" :confirm-loading="confirmLoading" :footer="null" :maskClosable="false">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span:6}" :wrapper-col="{span:18}">
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="头像">
              <QiniuUpload type="image" :value.sync="rowData.headPortrait"></QiniuUpload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="请选择经销商" :autoLink="false" ref="test" prop="dealerId">
              <a-select placeholder="请选择" v-model="rowData.dealerId"   @change="onDealerChange">
                <a-select-option :value="item.id" v-for="(item, index) in dealerList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="姓名" prop="name">
              <a-input v-model="rowData.name"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group v-model="rowData.sex">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="2">女</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="用户名" prop="userName">
              <a-input v-model="rowData.userName" placeholder="登陆账号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="所属部门" :autoLink="false" ref="test" prop="deptId">
              <a-select placeholder="请选择" v-model="rowData.deptId"  @change="onDepaChange">
                <a-select-option :value="item.id" v-for="(item, index) in deptList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="所属角色" :autoLink="false" ref="test" prop="roleId">
              <a-select placeholder="请选择" v-model="rowData.roleId"  @change="onRoleChange" mode="multiple">
                <a-select-option :value="item.id" v-for="(item, index) in roleList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="所属岗位" :autoLink="false" ref="test" prop="stationId">
              <a-select placeholder="请选择" v-model="rowData.stationId"  @change="onStationChange">
                <a-select-option :value="item.id" v-for="(item, index) in stationList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="12">
            <a-form-model-item label="所属职位" :autoLink="false" ref="test" prop="positionId">
              <a-select placeholder="请选择" v-model="rowData.positionId"  @change="onPositionChange">
                <a-select-option :value="item.id" v-for="(item, index) in positionList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="联系电话" prop="mobile">
              <a-input v-model="rowData.mobile" placeholder="初始密码为手机号后6位"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="邮箱" prop="email">
              <a-input v-model="rowData.email"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="出生年月" :autoLink="false" ref="test" prop="birthday">
              <DatePicker :startTime.sync="rowData.birthday"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="入职日期" :autoLink="false" ref="test" prop="joinDate">
              <DatePicker :startTime.sync="rowData.joinDate"></DatePicker>
            </a-form-model-item>
          </a-col>

        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="转正日期" :autoLink="false" ref="test" prop="formalDate">
              <DatePicker :startTime.sync="rowData.formalDate"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="离职日期" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.leaveDate"></DatePicker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="在职状态" prop="workStatus">
              <a-radio-group v-model="rowData.workStatus">
                <a-radio :value="1">试用期</a-radio>
                <a-radio :value="2">已转正</a-radio>
                <a-radio :value="3">已离职</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="状态" prop="flagEnable">
              <a-radio-group v-model="rowData.flagEnable">
                <a-radio :value="true">启用</a-radio>
                <a-radio :value="false">停用</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="学历" prop="education">
              <a-radio-group v-model="rowData.education">
                <a-radio :value="1">高中及以下</a-radio>
                <a-radio :value="2">大专</a-radio>
                <a-radio :value="3">本科</a-radio>
                <a-radio :value="4">研究生</a-radio>
                <a-radio :value="5">博士</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="居住地址">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="rowData.province"
                :city.sync="rowData.city"
                :district.sync="rowData.county"
                :provinceId.sync="rowData.provinceId"
                :cityId.sync="rowData.cityId"
                :districtId.sync="rowData.countyId">
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="rowData.address"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人">
              <a-input v-model="rowData.connectorName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人电话">
              <a-input v-model="rowData.connectorPhone"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="24">
            <a-form-model-item label="备注" :label-col="{span:3}" :wrapper-col="{span:21}">
              <a-textarea v-model="rowData.remark" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="onCancel()">取消</a-button>
        <a-button type="primary" @click="onSubmit()">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { addDealerManager, editDealerManager, selectByIdDealerManager } from '../api/FirmManagerApi.js'
import moment from 'moment'
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      rowData: {},
      handle: '',
      // 表单验证
      formRule: {
        dealerId: [
          {required: true, message: '请选择经销商', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '请选择性别', trigger: 'change'}
        ],
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        deptId: [
          {required: true, message: '请选择所属部门', trigger: 'change'}
        ],
        roleId: [
          {required: true, message: '请选择所属角色', trigger: 'change'}
        ],
        stationId: [
          {required: true, message: '请选择所属岗位', trigger: 'change'}
        ],
        positionId: [
          {required: true, message: '请选择所属职位', trigger: 'change'}
        ],
        mobile: [
          {required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur'}
        ],
        email: [
          {pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: '请输入正确的邮箱地址', trigger: 'blur'}
        ],
        birthday: [
          {required: true, message: '请选择出生年月', trigger: 'change'}
        ],
        joinDate: [
          {required: true, message: '请选择入职日期', trigger: 'change'}
        ],
        formalDate: [
          {required: false, message: '请选择转正日期', trigger: 'change'}
        ],
        workStatus: [
          {required: true, message: '请选择在职状态', trigger: 'change'}
        ],
        education: [
          {required: true, message: '请选择学历', trigger: 'change'}
        ],
      },
      deptList: [],
      roleList: [],
      stationList: [],
      positionList: [],
      dealerList: []
    }
  },
  created() {
  },
  methods: {
    isShow(row, name) {
      this.visible = true
      this.handle = name
      this.getList()
      this.rowData = {}
      this.rowData.birthday = null
      this.rowData.joinDate = null
      this.rowData.formalDate = null
      this.rowData.leaveDate = null
      if(name == 'add') {
        this.$set(this.rowData, 'flagEnable', true)
        this.$set(this.rowData, 'education', 1)
        this.$set(this.rowData, 'workStatus', 1)
        this.$set(this.rowData, 'sex', 1)
      }
      if(name == 'edit') {
        this.rowData.id = row.id
        selectByIdDealerManager(row.id).then(res => {
          this.rowData = res.body
          this.$set(this.rowData, 'provinceId', res.body.provinceId)
          this.$set(this.rowData, 'province', res.body.province)
          this.$set(this.rowData, 'cityId', res.body.cityId)
          this.$set(this.rowData, 'city', res.body.city)
          this.$set(this.rowData, 'countyId', res.body.countyId)
          this.$set(this.rowData, 'county', res.body.county)
          this.$set(this.rowData, 'address', res.body.address)
          this.$nextTick(() => {
            this.$refs.pcd.setPCD()
          })
        })
      }
    },
    getList() {
      // 获取经销商数据
      this.axios.get('/api/dealer/dealer/dealerInfo/listAll').then(res => {
        this.dealerList = res.body
      })
      // 获取部门数据
      this.axios.get('/api/base/system/department/listDealerAll').then(res => {
        this.deptList = res.body
      })
      // 获取角色数据
      this.axios.get('/api/firm/firm/firmRole/list?pageSize=99999').then(res => {
        this.roleList = res.body.records
      })
      // 获取岗位数据
      this.axios.get('/api/firm/firm/firmStation/list?pageSize=99999').then(res => {
        this.stationList = res.body
      }) 
      // 获取职位数据
      // this.axios.get('/api/dealer/dealer/dealerPosition/list?pageSize=9999').then(res => {
      //   this.positionList = res.body.records
      // })
    },

    onCancel() {
      this.visible = false
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        let url = this.handle == 'edit' ? editDealerManager : addDealerManager
        url(this.rowData).then(res => {
          if(res.code == 200 ) {
            this.$message.success(res.message)
            this.visible = false
            this.$emit('ok')
          }
        })
      })
    },
    onDepaChange(value) {
      this.$refs.test.onFieldChange()
    },
    onRoleChange(value) {
      this.$refs.test.onFieldChange()
    },
    onStationChange(val){
      this.$refs.test.onFieldChange()
    },
    onPositionChange(val){
      this.$refs.test.onFieldChange()
    },
    onDealerChange(val) {
      this.$refs.test.onFieldChange()
    }
  }
}
</script>

<style lang="scss">
.footer-bts {
  text-align: right;
}
</style>
